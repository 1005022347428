import {isWidthDown} from '@material-ui/core/withWidth';
import {Breakpoint} from '@material-ui/core/styles/createBreakpoints';

export function isMobile(width: Breakpoint) {
    return isWidthDown('sm', width);
}

export function computeBreakpoint(cols: number) {
    return Math.ceil(12 / cols) as any;
}
