/* eslint-disable */
import React from 'react';
import {graphql} from 'gatsby';
import SEO from '../modules/layout/containers/SEO';
import _get from 'lodash/get';
import {Layout} from '../modules/layout/containers/Layout';
import {mapProductCategoryNodeToCollection} from '../modules/core/util/CollectionUtil';
import {CollectionPageContent} from '../modules/collections/containers/CollectionPageContent';
import {filterProductNodeList} from '../modules/core/util/ProductUtil';
import {useSiteMetadata} from '../modules/common/hooks/useSiteMetadata';
import {computeSEOMeta} from '../modules/core/util/SEOUtil';

interface Props {
    data: any;
    location: Location;
}

const CollectionPageTemplate: React.FC<Props> = props => {
    const collNode = _get(props, 'data.wpgraphql.productCategory');
    const prodNodes = filterProductNodeList(collNode.products.edges);
    const coll = mapProductCategoryNodeToCollection(collNode, prodNodes);
    const siteMetadata = useSiteMetadata();
    const {title, metadata} = computeSEOMeta(siteMetadata, coll.seo);
    return (
        <Layout location={props.location}>
            <SEO title={title} meta={metadata} />
            <CollectionPageContent collection={coll} />
        </Layout>
    );
};

export default CollectionPageTemplate;

export const pageQuery = graphql`
    query GetCollectionQuery($id: ID!) {
        wpgraphql {
            productCategory(id: $id) {
                ...ProductCategoryFragment
                products(first: 1000) {
                    edges {
                        node {
                            ...ProductInfoFragment
                            ...ProductAttrsFragment
                            ...ProductImageThumbMFragment
                        }
                    }
                }
            }
        }
    }
`;
