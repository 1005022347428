import {ProductVariation} from '../models/ProductVariationOld';
import {mapNodeToProductCore} from './ProductMapper';
import {mapProductVariationItemAttributeEdges} from './ProductAttributesMapper';

export function mapProductVariationNode(node: any): ProductVariation {
    const {attributes} = node;
    const prodCore: ProductVariation = mapNodeToProductCore(node) as any;
    prodCore.variationId = node.variationId;
    delete prodCore.infoAttributes;
    delete prodCore.variationAttributes;
    const itemAttrs = mapProductVariationItemAttributeEdges(attributes.edges);
    return {
        ...prodCore,
        variationItemAttributes: itemAttrs,
    };
}

export function mapProductVariations(productNode: any): ProductVariation[] | undefined {
    if (!productNode.variations || !productNode.variations.edges) {
        return undefined;
    }
    return productNode.variations.edges.map((edge: any) => mapProductVariationNode(edge.node));
}
