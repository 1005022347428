import {WCProductAttributes} from '../models/WCProductAttributes';
import {ProductInfoAttr} from '../models/ProductCore';
import {ProductAttributeWithSelection} from '../models/ProductAttributeWithSelection';
import {ProductAttributeCore} from '../models/ProductAttributeCore';
import {ProductVariationAttribute} from '../models/ProductVariationAttribute';

export function mapProductInfoAttributeEdges(edges: any[]): ProductInfoAttr | undefined {
    if (!edges) return;
    const infoAttrs = edges.filter(item => item.node && !item.node.variation);
    // FIXME Pending to sort them by position
    const attrDict: any = {};
    for (const item of infoAttrs) {
        const attr: WCProductAttributes = item.node;
        if (!attr.options || attr.options.length === 0) continue;
        attrDict[attr.name] = attr.options[0];
    }
    return attrDict;
}

export function mapProductAttributeCore(node: any): ProductAttributeCore {
    return {
        id: node.id,
        attributeId: node.attributeId,
        name: node.name,
        position: node.position,
        variation: node.variation,
        visible: node.visible,
    };
}

export function mapProductVariationAttributeEdges(edges: any[]): ProductAttributeWithSelection[] | undefined {
    if (!edges) return;
    const filterAttrs = edges.filter(
        item => item.node && item.node.variation && item.node.options && item.node.options.length > 0,
    );
    const attrs: ProductAttributeWithSelection[] = filterAttrs.map(({node}) => ({
        ...mapProductAttributeCore(node),
        options: node.options,
        selectedOption: undefined,
    }));
    return attrs;
}

export function mapProductVariationItemAttributeEdges(edges: any[]): ProductVariationAttribute[] | undefined {
    if (!edges) return;
    const attrs: ProductVariationAttribute[] = edges.map(({node}) => ({
        ...mapProductAttributeCore(node),
        value: node.value,
    }));
    return attrs;
}
