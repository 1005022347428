import {Collection} from '../models/Collection';
import {mapNodeListToProduct} from '../mapper/ProductMapper';

export function mapProductCategoryNodeToCollection(node: any, prodNodes: any[]): Collection {
    const products = !prodNodes ? null : mapNodeListToProduct(prodNodes);
    return {
        id: node.id,
        name: node.name,
        description: node.description,
        display: node.display,
        slug: node.slug,
        wordpressId: node.wordpressId,
        priority: node.menu_order,
        count: node.count,
        image: node.image,
        seo: node.seo,
        products,
    };
}
