import React from 'react';
import classNames from 'classnames';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {BoxProps} from '@material-ui/core/Box/Box';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        stamp: {
            display: 'flex',
            alignItems: 'center',
            backgroundColor: theme.palette.secondary.main,
            padding: theme.spacing(2),
            borderRadius: '50%',
            width: 60,
            height: 60,
        },
        badge: {
            position: 'absolute',
            top: 0,
            right: 0,
            zIndex: 1050,
        },
        text: {
            fontWeight: 'bold',
            textTransform: 'uppercase',
            color: theme.palette.common.white,
        },
    }),
);

interface Props extends BoxProps {
    badgeMode?: boolean;
}

export const OnSaleStamp: React.FC<Props> = ({badgeMode, ...other}) => {
    const classes = useStyles();
    return (
        <Box className={classNames(classes.stamp, {[classes.badge]: badgeMode})} color="secondary" {...other}>
            <Typography className={classes.text} variant="subtitle2">
                Sale
            </Typography>
        </Box>
    );
};

OnSaleStamp.defaultProps = {
    badgeMode: true,
};
