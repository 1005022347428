import React from 'react';
import {ProductCore} from '../../core/models/ProductCore';
import {BaseProductPriceLabel} from './BaseProductPriceLabel';

interface Props {
    product: ProductCore;
}

export const ProductPriceLabel: React.FC<Props> = ({product}) => {
    const isOnSale = product.meta && product.meta.onSale;
    return (
        <BaseProductPriceLabel
            price={product.meta.priceFmt}
            comparePrice={product.meta.regularPriceFmt}
            onSale={isOnSale}
        />
    );
};
