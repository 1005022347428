import React from 'react';
import Img from 'gatsby-image';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import {Collection} from '../../core/models/Collection';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            marginBottom: theme.spacing(3),
        },
        image: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
    }),
);

interface Props {
    collection: Collection;
}

export const CollectionSummary: React.FC<Props> = ({collection}) => {
    if (!collection || !collection.description) return null;
    const classes = useStyles();
    const image = collection.image;
    const imageEl =
        image && image.imageFile ? <Img fixed={image.imageFile.childImageSharp.fixed} alt={image.altText} /> : null;
    const hasImage = !!imageEl;
    return (
        <Grid container className={classes.container} spacing={2}>
            <Grid item md={hasImage ? 6 : 12}>
                <Typography variant="body1" dangerouslySetInnerHTML={{__html: collection.description}} />
            </Grid>
            {hasImage && (
                <Grid className={classes.image} item md={6}>
                    {imageEl}
                </Grid>
            )}
        </Grid>
    );
};
