import React from 'react';
import {Collection} from '../../core/models/Collection';
import Container from '@material-ui/core/Container';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import {HRule} from '../../uxui/HRule';
import {ProductGrid} from '../../product/containers/ProductGrid';
import {CollectionSummary} from '../components/CollectionSummary';
import {NavLink} from '../../uxui/NavLink';

interface Props {
    collection: Collection;
}

export const CollectionPageContent: React.FC<Props> = ({collection}) => {
    const {products} = collection;
    const productGrid =
        products && products.length > 0 ? (
            <ProductGrid products={products} thumbStyle={{maxWidth: 250, maxHeight: 250}} desktopCols={3} />
        ) : (
            <Typography variant="body1">No products found.</Typography>
        );
    return (
        <Container maxWidth="lg">
            <Breadcrumbs aria-label="breadcrumb">
                <NavLink to={'/'} style={{color: 'inherit'}}>
                    Home
                </NavLink>
                <NavLink color="inherit" to={'/collections/all'} style={{color: 'inherit'}}>
                    Products
                </NavLink>
                <Typography color="textPrimary" style={{fontSize: 'inherit'}}>
                    {collection.name}
                </Typography>
            </Breadcrumbs>
            <HRule />
            <CollectionSummary collection={collection} />
            {productGrid}
        </Container>
    );
};
