/* eslint-disable camelcase */
import React, {CSSProperties} from 'react';
import Grid from '@material-ui/core/Grid';
import {ProductItem} from '../components/ProductItem';
import {Product} from '../../core/models/Product';
import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {computeBreakpoint} from '../../core/util/ResponsiveUtil';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            padding: theme.spacing(2),
        },
    }),
);

interface Props {
    desktopCols?: number;
    mobileCols?: number;
    products: Product[];
    thumbStyle?: CSSProperties;
}

export const ProductGrid: React.FC<Props> = ({desktopCols, mobileCols, products, thumbStyle}) => {
    const classes = useStyles();
    const md = computeBreakpoint(desktopCols!);
    const xs = computeBreakpoint(mobileCols!);
    const productItems = products.map(item => {
        const path = `/product/${item.slug}/`;
        const key = item.id;
        return (
            <Grid key={key} item xs={xs} md={md}>
                <ProductItem linkTo={path} product={item} thumbStyle={thumbStyle} />
            </Grid>
        );
    });
    return (
        <Grid container spacing={2} className={classes.container}>
            {productItems}
        </Grid>
    );
};

ProductGrid.defaultProps = {
    desktopCols: 4,
    mobileCols: 1,
};
