import {Product} from '../models/Product';
import _orderBy from 'lodash/orderBy';

export function filterProductNodeList(edges: any[]) {
    if (!edges) return [];
    return edges
        .filter(({node}) => {
            return node.status === 'publish';
        })
        .map(({node}) => node);
}

export function sortFeaturedProducts(products: Product[]) {
    const sortFunc = (item: Product) => (item.featured ? 1000 + item.priority : item.priority);
    return _orderBy(products, [sortFunc], ['desc']);
}

export function getProductPrice(product: Product): number {
    return product.variations && product.variations.length ? product.variations[0].meta.price : product.meta.price;
}

export function sortDealsProducts(products: Product[]) {
    const sortFunc = (item: Product) => {
        const score = item.meta.onSale ? -1000 + getProductPrice(item) : getProductPrice(item);
        console.log('sortDetals:', item.id, item.name, score, item.meta.onSale, item.meta.price);
        return score;
    };
    return _orderBy(products, [sortFunc], ['asc']);
}

export function productHasVariations(product: Product): boolean {
    return !!(product && product.variations && product.variations.length > 0);
}
