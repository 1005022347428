import React, {CSSProperties} from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {ProductCore} from '../../core/models/ProductCore';
import Img from 'gatsby-image';
import {ProductPriceLabel} from './ProductPriceLabel';
import {Colors} from '../../../theme';
import {NavLink} from '../../uxui/NavLink';
import {OnSaleStamp} from './OnSaleStamp';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {},
        imgWrapper: {
            position: 'relative',
            margin: 'auto',
            '&:hover img, &:hover picture': {
                transform: 'scale(1.1)',
            },
        },
        responsive: {
            maxWidth: '100%',
            height: 'auto',
            objectFit: 'contain',
        },
        details: {
            color: Colors.BLACK,
            marginTop: theme.spacing(1),
        },
        vendor: {
            textAlign: 'center',
            textTransform: 'uppercase',
            color: theme.palette.primary.main,
        },
        title: {
            textTransform: 'uppercase',
            textAlign: 'center',
        },
        price: {
            textAlign: 'center',
        },
    }),
);

interface Props {
    thumbStyle?: CSSProperties;
    linkTo: string;
    product: ProductCore;
}

export const ProductItem: React.FC<Props> = ({linkTo, product, thumbStyle}) => {
    const classes = useStyles();
    const mainImage = product.images && product.images.length > 0 ? product.images[0] : null;
    const isOnSale = product.meta.onSale;
    // transition: 'transform 0.5s ease',
    return (
        <NavLink to={linkTo}>
            <Box className={classes.container}>
                {mainImage && mainImage.imageFile && (
                    <Box className={classes.imgWrapper} style={{...thumbStyle}}>
                        {isOnSale && <OnSaleStamp />}
                        <Img
                            className={classes.responsive}
                            fixed={mainImage.imageFile.childImageSharp.fixed}
                            fluid={mainImage.imageFile.childImageSharp.fluid}
                            alt={mainImage.altText}
                            imgStyle={{transitionProperty: 'opacity, transform'}}
                        />
                    </Box>
                )}
                <Box className={classes.details}>
                    <Typography className={classes.vendor} variant="body2">
                        {product.vendor}
                    </Typography>
                    <Typography className={classes.title} variant="body1">
                        {product.name}
                    </Typography>
                    <Typography className={classes.price} variant="body2">
                        <ProductPriceLabel product={product} />
                    </Typography>
                </Box>
            </Box>
        </NavLink>
    );
};
