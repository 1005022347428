import React from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        price: {
            display: 'block-inline',
            color: theme.palette.primary.main,
            marginRight: theme.spacing(1),
        },
        strikeOut: {
            color: theme.palette.secondary.main,
            textDecoration: 'line-through',
        },
    }),
);

interface Props {
    price: string;
    comparePrice: string;
    onSale: boolean;
}

export const BaseProductPriceLabel: React.FC<Props> = ({price, comparePrice, onSale}) => {
    const classes = useStyles();
    return (
        <>
            <span className={classes.price}>{price}</span>
            {onSale ? <span className={classes.strikeOut}>{comparePrice}</span> : null}
        </>
    );
};
