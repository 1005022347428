import {Product} from '../models/Product';
import {ProductCore} from '../models/ProductCore';
import {ProductVariation} from '../models/ProductVariationOld';
import {mapProductVariations} from './ProductVariationMapper';
import {mapProductInfoAttributeEdges, mapProductVariationAttributeEdges} from './ProductAttributesMapper';
import numeral from 'numeral';

export function mapNodeToProductCore(node: any): ProductCore {
    const {
        average_rating,
        id,
        image,
        galleryImages,
        name,
        attributes,
        menuOrder,
        onSale,
        reviewsCount,
        featured,
        price,
        regularPrice,
        salePrice,
        sku,
        slug,
        status,
        productId,
        purchasable,
    } = node;
    const infoAttributes = attributes ? mapProductInfoAttributeEdges(attributes.edges) : undefined;
    const variationAttributes = attributes ? mapProductVariationAttributeEdges(attributes.edges) : undefined;
    const images = galleryImages ? galleryImages : [image];
    const variations: ProductVariation[] | undefined = mapProductVariations(node);
    let vendor: any = undefined;
    if (infoAttributes && infoAttributes.brand) {
        vendor = infoAttributes.brand;
        // console.log('Moving attrBrand to vendor:', vendor);
        delete infoAttributes.brand;
    }
    const isVariableProduct =
        (variationAttributes && variationAttributes.length > 0) || (variations && variations.length > 0);
    return {
        id,
        productId,
        slug,
        infoAttributes,
        variationAttributes,
        mainImage: image,
        images,
        name,
        featured,
        priority: menuOrder,
        variations,
        meta: {
            averageRating: average_rating,
            reviewsCount,
            onSale,
            price: price && !isVariableProduct ? numeral(price).value() : price,
            priceFmt: price,
            salePrice: salePrice && !isVariableProduct ? numeral(salePrice).value() : salePrice,
            salePriceFmt: salePrice,
            status,
            regularPrice: regularPrice && !isVariableProduct ? numeral(regularPrice).value() : regularPrice,
            regularPriceFmt: regularPrice,
            purchasable,
        },
        sku,
        vendor,
    };
}

export function mapNodeToProduct(node: any): Product {
    const {description, related, shortDescription, ...other} = node;
    const productCore = mapNodeToProductCore(node);
    const relProds: ProductCore[] =
        related && related.edges ? related.edges.map((edge: any) => mapNodeToProductCore(edge.node)) : null;
    return {
        ...productCore,
        description,
        shortDescription,
        relatedProducts: relProds,
        additionalData: other,
    };
}

export function mapEdgeNodeListToProduct(edges: any) {
    return edges.map((edge: any) => mapNodeToProduct(edge.node));
}

export function mapNodeListToProduct(nodes: any) {
    return nodes.map((node: any) => mapNodeToProduct(node));
}
