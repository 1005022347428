import React from 'react';
import Box from '@material-ui/core/Box';
import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {Colors} from '../../theme';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        hrule: {
            borderTop: `1px solid ${Colors.GRAY}`,
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
    }),
);

export const HRule: React.FC = () => {
    const classes = useStyles();
    return <Box className={classes.hrule} />;
};
